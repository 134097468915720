//-------------CONSTANTS---------------
export const FETCHING_COUPON_LISTING = 'FETCHING_COUPON_LISTING';
export const GEETING_COUPON_LISTING = 'GEETING_COUPON_LISTING';
export const IS_COUPON_APPLIED = 'IS_COUPON_APPLIED';
export const IS_HOLLOW_COUPON_APPLIED = 'IS_HOLLOW_COUPON_APPLIED';
export const IS_CUSTOM_COUPON_APPLIED = 'IS_CUSTOM_COUPON_APPLIED';
export const IS_CUSTOM_DOUBLE_DIP_COUPON_APPLIED =
  'IS_CUSTOM_DOUBLE_DIP_COUPON_APPLIED';
export const GETTING_COUPON_RESPONSE = 'GETTING_COUPON_RESPONSE';
export const GETTING_APPLIED_COUPON_ID = 'GETTING_APPLIED_COUPON_ID';
export const GETTING_APPLIED_COUPON_CODE = 'GETTING_APPLIED_COUPON_CODE';
export const FETCHING_REMOVE_COUPON = 'FETCHING_REMOVE_COUPON';
export const FETCHING_REMOVE_HOLLOW_COUPON = 'FETCHING_REMOVE_HOLLOW_COUPON';
export const FETCHING_REMOVE_CUSTOM_COUPON = 'FETCHING_REMOVE_CUSTOM_COUPON';
export const FETCHING_REMOVE_CUSTOM_DOUBLE_DIP_COUPON =
  'FETCHING_REMOVE_CUSTOM_DOUBLE_DIP_COUPON';

//--------------ACTIONS----------------
export function fetchingCoupon(status) {
  return {
    type: FETCHING_COUPON_LISTING,
    payload: status,
  };
}
export function gettingCoupon(data) {
  return {
    type: GEETING_COUPON_LISTING,
    payload: data,
  };
}
export function couponAppliedStatus(status) {
  return {
    type: IS_COUPON_APPLIED,
    payload: status,
  };
}

export function hollowCouponAppliedStatus(status) {
  return {
    type: IS_HOLLOW_COUPON_APPLIED,
    payload: status,
  };
}
export function customCouponAppliedStatus(status) {
  return {
    type: IS_CUSTOM_COUPON_APPLIED,
    payload: status,
  };
}
export function customDoubleDipCouponAppliedStatus(status) {
  return {
    type: IS_CUSTOM_DOUBLE_DIP_COUPON_APPLIED,
    payload: status,
  };
}
export function gettingCouponResponse(data) {
  return {
    type: GETTING_COUPON_RESPONSE,
    payload: data,
  };
}
export function applyingCouponId(id) {
  return {
    type: GETTING_APPLIED_COUPON_ID,
    payload: id,
  };
}
export function applyingCouponCode(id) {
  return {
    type: GETTING_APPLIED_COUPON_CODE,
    payload: id,
  };
}
export function removeCouponStatus(data) {
  if (data.type == 'SiteWide') {
    return {
      type: FETCHING_REMOVE_COUPON,
      payload: data.status,
    };
  } else if (data.type == 'CustomCoupon') {
    return {
      type: FETCHING_REMOVE_CUSTOM_COUPON,
      payload: data.status,
    };
  } else if (data.type == 'HollowCoupon') {
    return {
      type: FETCHING_REMOVE_HOLLOW_COUPON,
      payload: data.status,
    };
  } else {
    return {
      type: FETCHING_REMOVE_CUSTOM_DOUBLE_DIP_COUPON,
      payload: data.status,
    };
  }
}
